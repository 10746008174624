import { BaseNavigation } from "@crafthunt-layout/BaseNavigation/BaseNavigation";
import { Loading } from "@crafthunt-ui/Loading/Loading";
import { UserIcon } from "@heroicons/react/24/outline";
import { AuthRoutesEnum } from "constants/routes.constants";
import { useAuth } from "features/auth/context/AuthContext";
import { interpolation } from "features/translations/helpers/translation.helper";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useRouter } from "next/router";
import { StoryblokSettingsDef } from "types/storyblok.types";
import { getTranslatedSlug } from "utils/router.helper";
import { AuthNavItem } from "./components/AuthNavItem/AuthNavItem";

type AuthNavigationProps = {
  settings: StoryblokSettingsDef;
};

export const AuthNavigation = ({ settings }: AuthNavigationProps) => {
  const { t } = useTranslation();
  const { isLoggedIn, parsedToken, user } = useAuth();
  const { isCrafthuntCompanyAdmin } = parsedToken || {};
  const router = useRouter();

  const isApplyPage = router.pathname.startsWith("/job-apply/");

  const onlyBauGPT = settings?.navigation.find(
    (item) =>
      item.url.url?.includes("baugpt") ||
      item.url.cached_url?.includes("baugpt")
  );

  return (
    <BaseNavigation
      className={isCrafthuntCompanyAdmin || isApplyPage ? "" : "hidden lg:grid"}
      leftMenu={
        onlyBauGPT
          ? [
              {
                id: "navigation-baugpt",
                label: onlyBauGPT.name,
                link: getTranslatedSlug(onlyBauGPT),
              },
            ]
          : null
      }
      rightMenu={
        isLoggedIn ? (
          <>
            {!isCrafthuntCompanyAdmin && (
              <AuthNavItem
                id="navigation-my-jobs"
                link={AuthRoutesEnum.JOBS}
                text={t("navigation-my-jobs")}
              />
            )}
            <AuthNavItem
              id="navigation-my-chats"
              link={AuthRoutesEnum.MY_JOB_APPLICATIONS}
              text={t("navigation-my-chats")}
            />
            <Link
              id="navigation-my-profile"
              href={AuthRoutesEnum.PROFILE}
              className="flex items-center rounded-sm px-3 py-2 text-sm font-medium text-gray-900 md:bg-gray-700 md:text-white md:hover:bg-gray-600"
            >
              <UserIcon className="size-4 mr-2" />
              {interpolation(t("navigation-hello-user"), [user.firstName])}
            </Link>
          </>
        ) : (
          <Loading isWhite />
        )
      }
    />
  );
};
