import Head, { HeadProps } from "@crafthunt-ui/Head";
import { NavbarCTADef } from "@crafthunt-ui/NavbarCTA/NavbarCTA";
import { TranslatedSlugDef } from "features/translations/types/translation.types";
import { ReactNode } from "react";
import { StoryblokSettingsDef } from "types/storyblok.types";
import Footer from "../Footer/Footer";
import Navigation from "../Navigation/Navigation";

export type LayoutProps = {
  children?: ReactNode;
  locale: string;
  seoObj?: HeadProps["meta"];
  settings?: StoryblokSettingsDef;
  translatedSlugs?: TranslatedSlugDef[];
  hideFromRobots?: boolean;
  hideNav?: boolean;
  customNavbarAction?: NavbarCTADef[];
};

const Layout = ({
  children,
  locale,
  seoObj,
  settings,
  translatedSlugs,
  hideFromRobots,
  hideNav = false,
  customNavbarAction,
}: LayoutProps) => {
  return (
    <div className="bg-gray-900">
      <Head
        meta={seoObj}
        locale={locale}
        translatedSlugs={translatedSlugs}
        hideFromRobots={hideFromRobots}
      />
      <Navigation
        locale={locale}
        settings={settings}
        translatedSlugs={translatedSlugs}
        hideNav={hideNav}
        customNavbarAction={customNavbarAction}
      />
      <main>{children}</main>
      <Footer settings={settings} translatedSlugs={translatedSlugs} />
    </div>
  );
};

export default Layout;
