import {
  BaseNavigation,
  NavigationItem,
} from "@crafthunt-layout/BaseNavigation/BaseNavigation";
import { CustomButton } from "@crafthunt-ui/CustomButton/CustomButton";
import { NavbarCTA, NavbarCTADef } from "@crafthunt-ui/NavbarCTA/NavbarCTA";
import { PhoneIcon } from "@heroicons/react/20/solid";
import { STORYBLOK_COMPANY_PAGES } from "constants/routes.constants";
import { LoginButton } from "features/auth/components/LoginButton/LoginButton";
import { SignUpButton } from "features/auth/components/SignUpButton/SignUpButton";
import { TranslatedSlugDef } from "features/translations/types/translation.types";
import { useRouter } from "next/router";
import { StoryblokSettingsDef } from "types/storyblok.types";
import { removeWhitespace } from "utils/helpers";
import { getTranslatedSlug } from "utils/router.helper";

type NavigationProps = {
  locale: string;
  settings?: StoryblokSettingsDef;
  translatedSlugs?: TranslatedSlugDef[];
  /** If the left menu items should be hidden, this is controlled in CMS */
  hideNav?: boolean;
  /** If the auth user navigation part should be hidden */
  hideAuthNav?: boolean;
  customNavbarAction?: NavbarCTADef[];
};

export const DefaultNavigation = ({
  locale,
  settings,
  translatedSlugs,
  hideNav,
  hideAuthNav,
  customNavbarAction,
}: NavigationProps) => {
  const router = useRouter();
  const viewingCompanyPage = STORYBLOK_COMPANY_PAGES.some((cp) =>
    router.asPath.includes(cp)
  );

  if (viewingCompanyPage) {
    return (
      <BaseNavigation
        rightMenu={
          customNavbarAction?.length ? (
            <>
              {customNavbarAction.map((cta) => (
                <NavbarCTA key={cta._uid} blok={cta} />
              ))}
            </>
          ) : (
            <>
              {["de", "en"].includes(locale) && settings?.nav_phone && (
                <CustomButton
                  id="navigation-company-phone"
                  externalHref={`tel:${removeWhitespace(settings.nav_phone)}`}
                >
                  <PhoneIcon className="h-4 w-4 inline mb-0.5 mr-1.5" />
                  {settings.nav_phone}
                </CustomButton>
              )}
            </>
          )
        }
        showLanguagePicker
        showLanguageDisclaimerBanner
        hideHamburgerMenu
      />
    );
  }

  return (
    <BaseNavigation
      disableLogoLink={hideAuthNav}
      hideHamburgerMenu={hideAuthNav}
      leftMenu={
        !hideNav
          ? settings?.navigation?.map(
              (item) =>
                ({
                  id: item._uid,
                  label: item.name,
                  link: getTranslatedSlug(item),
                } as NavigationItem)
            )
          : []
      }
      rightMenu={
        !hideAuthNav &&
        (customNavbarAction?.length ? (
          <>
            {customNavbarAction.map((cta) => (
              <NavbarCTA key={cta._uid} blok={cta} />
            ))}
          </>
        ) : (
          <>
            <LoginButton id="navigation-login" />
            <SignUpButton id="navigation-sign-up" />
          </>
        ))
      }
      showLanguageDisclaimerBanner
      showLanguagePicker
      locale={locale}
      translatedSlugs={translatedSlugs}
    />
  );
};
