import { AuthRoutesEnum, RoutesEnum } from "constants/routes.constants";
import { useAuth } from "features/auth/context/AuthContext";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import {
  MdChat,
  MdHomeRepairService,
  MdOutlineSmartToy,
  MdPerson,
} from "react-icons/md";
import { BottomNavItem } from "./components/BottomNavItem";

export const AuthBottomNavigation = () => {
  const { t } = useTranslation();
  const { isLoggedIn, parsedToken } = useAuth();
  const { isCrafthuntCompanyAdmin } = parsedToken || {};
  const router = useRouter();

  const isApplyPage = router.pathname.startsWith("/job-apply/");

  if (!isLoggedIn || isCrafthuntCompanyAdmin || isApplyPage) {
    return <></>;
  }

  return (
    <div className="lg:hidden fixed bottom-0 left-0 z-50 w-full h-20 bg-white border-t border-gray-100 pb-5">
      <div className="grid h-full max-w-lg grid-cols-4 mx-auto font-medium">
        <BottomNavItem
          link={AuthRoutesEnum.JOBS}
          icon={MdHomeRepairService}
          text={t("navigation-my-jobs")}
        />
        <BottomNavItem
          link={RoutesEnum.BAUGPT}
          icon={MdOutlineSmartToy}
          text="BauGPT"
        />
        <BottomNavItem
          link={AuthRoutesEnum.MY_JOB_APPLICATIONS}
          icon={MdChat}
          text={t("navigation-my-chats")}
        />
        <BottomNavItem
          link={AuthRoutesEnum.PROFILE}
          icon={MdPerson}
          text={t("navigation-my-profile")}
        />
      </div>
    </div>
  );
};
